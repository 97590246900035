import React from 'react';
import { Container, Typography, Box } from '@mui/material';

function App() {
  return (
    <Container maxWidth="md" sx={{ textAlign: 'center', padding: '20px', backgroundColor: '#f5f5f5', borderRadius: '8px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
        <img src="/images/icon.png" alt="App Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
        <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#3f51b5', fontSize: '2.5rem', lineHeight: '40px', height: '40px' }}>
          Recipe Extractor
        </Typography>
      </Box>
      
      <Typography variant="body1" sx={{ margin: '20px 0', color: '#555' }}>
        Copy the URL from your favorite recipe website.
      </Typography>
      
      <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
        <video
          maxWidth="auto"
          height="600px"
          controls
          style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <source src="/videos/CopyChurrosRecipeURL.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
      
      <Typography variant="body1" sx={{ margin: '20px 0', color: '#555' }}>
        Paste the URL in the iOS app.
      </Typography>
      
      <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
        <video
          maxWidth="auto"
          height="600px"
          controls
          style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
        >
          <source src="/videos/AppPasteBrowseChurrosRecipe.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>
    </Container>
  );
}

export default App;
